/**
 * /tmsection/で使用するJS
 *
 * ProductLinkAct: 製品情報ページで「関連文書」のリンクがあった時のCLASS付加の制御
 * productTableAct: 製品情報ページでtableにスクロールバーを付加する制御 ※simplebar.jsのimport
 * productSeachAct: 製品情報ページの製品番号検索の制御
 * productModalAct: 製品情報ページのモーダルOPEN
 * productModalCloseAct: 製品情報ページのモーダルCLOSE
 *
 */

import ProductLinkAct from './tmsectionAct/productLinkAct';
import ProductTableAct from './tmsectionAct/productTableAct';
import ProductSeachAct from './tmsectionAct/productSeachAct';
import ProductModalAct from './tmsectionAct/productModalAct';
import ProductModalCloseAct from './tmsectionAct/productModalCloseAct';

class Tmsection {
  constructor() {
    this.productLinkAct = new ProductLinkAct('.bdromcol5 a','.bdromcol5','p-tmsectionPdTable__productItemLink');
    this.productTableAct = new ProductTableAct('.p-tableWrapper');
    this.productSeachAct = new ProductSeachAct('#search-modelNumber', '.p-tableWrapper');
    this.productModalAct = new ProductModalAct('.js-modalOpen','.p-tmsectionModal-open');
    this.productModalCloseAct = new ProductModalCloseAct('.p-tmsectionModal-open','.p-tmsectionModal__close','.p-tmsectionModal__back');
  }
  init() {
    this.productLinkAct.init();
    this.productTableAct.init();
    this.productSeachAct.init();
    this.productModalAct.init();
    this.productModalCloseAct.init();
  }
}

export default Tmsection;