import SimpleBar from "simplebar";

export default class productTableAct {
    /**
     *
     * @param {string} divObj eachの対象obj
     *
     */
  constructor(divObj) {
    this.divObj = divObj; // .p-tableWrapper
  }
  init() {
    const nodeList = document.querySelectorAll(this.divObj);
    const arr = jQuery.makeArray(nodeList);
    arr.forEach(
      el => new SimpleBar(el, {
        autoHide: false
      })
    );
  }
}
