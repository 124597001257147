/**
 * 追従サイドナビ
 */
class SpHeaderMenu {
    constructor() {

    }
    init() {
        $('.l-header__spBtn').on('click', function () {
            $(this).toggleClass('is-active');
            $('.l-header__spMenu').toggleClass('is-open');
        })
        $('.l-header__spMenuTtl--toggle').on('click', function () {
            $(this).next().stop().slideToggle(300);
            $(this).toggleClass('is-open');
        })
    }
}

export default SpHeaderMenu;
