/**
 * アコーディオン
 */
class Accordion {
  constructor() {

  }
  init() {
    $(function(){
      //アコーディオン　＋、－動き
    //   $('.p-archive__acdTtl').on('click',function () {
    //       $(this).next('.p-archive__acdTable').slideToggle(800);
    //       // activeが存在する場合
    //       if ($(this).children(".p-archive__acdIcon").hasClass('active')) {
    //           // activeを削除
    //           $(this).children(".p-archive__acdIcon").removeClass('active');
    //       }
    //       else {
    //           // activeを追加
    //           $(this).children(".p-archive__acdIcon").addClass('active');
    //       }
    //       return false;
    //   });
  });
  $(function(){
    //アコーディオン　＋、－動き
    $('.p-archiveFaq__question').on('click',function () {
        $(this).next('.p-archiveFaq__answer').slideToggle(800);
        // activeが存在する場合
        if ($(this).children(".p-archiveFaq__acdIcon").hasClass('active')) {
            // activeを削除
            $(this).children(".p-archiveFaq__acdIcon").removeClass('active');
        }
        else {
            // activeを追加
            $(this).children(".p-archiveFaq__acdIcon").addClass('active');
        }
        return false;
    });
});
  }
}

export default Accordion;