export default class ProductModalAct {
    /**
     *
     * @param {string} modalBtnObj モーダルを立ち上げるaタグに付加しているクラス名
     * @param {string} modalObj モーダルのクラス名
     *
     */
    constructor (modalBtnObj,modalObj) {
        this.modalBtnObj = modalBtnObj; // .js-modalOpen
        this.modalObj = modalObj; // .p-tmsectionModal-open
    }
  init() {
    let $modalObj = $(this.modalObj),
      $modalTtl = $('.p-tmsectionModalCont__ttl'),
      $modalImg = $('.p-tmsectionModalCont__img'),
      modalTextClass = '.js-modalTextNum0';

    $(this.modalBtnObj).on('click', (e) => {
      let modalVal = $(e.currentTarget).attr('data-modalName');
      $modalObj.show();
      const expr = modalVal;
      switch (expr) {
        case 'radialrunout':
          modalContent('偏芯','radialrunout',1);
          break;
        case 'verticaldeviation':
          modalContent('面振れ','verticaldeviation',2);
          break;
        case 'unbalance':
          modalContent('偏重心','unbalance',3);
          break;
        case 'defect':
          modalContent('ディフェクト','defect',4);
          break;
        case 'scratch':
          modalContent('スクラッチ','scratch',5);
          break;
        case 'thickness':
          modalContent('厚み','',6);
          break;
        case 'nonstandardized':
          modalContent('規格外テストディスクシリーズ','',7);
          break;
      }
    });

    const modalContent = (text,src,textNum) => {
      $modalTtl.text(text);
      $(modalTextClass + textNum).show();
      if (textNum == 6) {
        $modalImg.hide();
      } else if (textNum == 7) {
        $modalImg.hide();
      } else {
        $modalImg.show();
        $modalImg.attr('src', '/assets/img/tmsection/' + src + '_j.png');
      }
    }
  }
}
