import {TweenMax} from "gsap";
import {TimelineMax} from "gsap";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
const controller = new ScrollMagic.Controller;
/**
 * スクロール
 */
class topScroll {
    constructor() {}
    init() {
        new ScrollMagic.Scene({
            triggerElement: '.p-top__mainTtl--business',
            reverse: true,
        },'-=0.1')
        .setClassToggle('.p-top__scroll--business', 'active')
//        .addIndicators({name: "kvScrollIcon_top"})
        .addTo(controller);


        new ScrollMagic.Scene({
            triggerElement: '.p-top__mainTtl--news',
            reverse: true,
        },'-=0.1')
        .setClassToggle('.p-top__scroll--news', 'active')
//        .addIndicators({name: "kvScrollIcon_center"})
        .addTo(controller);


        new ScrollMagic.Scene({
            triggerElement: '.p-top__mainTtl--content',
            reverse: true,
        })
        .setClassToggle('.p-top__scroll--content', 'active')
//        .addIndicators({name: "kvScrollIcon_bottom"})
        .addTo(controller);
    }
}

export default topScroll;
