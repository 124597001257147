import Swiper from "swiper";
/**
 * スライダー
 */
class topSlider {
  constructor() {}
  init() {
    var swiper = new Swiper('.swiper-container', {
      autoplay: {
        delay: 5000,
        disableOnInteraction: true
      },
      loop: true,
      speed: 1500,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    });
  }
}

export default topSlider;