/**
 * 追従サイドナビ
 */
class FooterAcc {
    constructor() {

    }
    init() {
        var sp = window.matchMedia("(max-width: 750px)").matches;
        if (sp) {
            $('.l-footerLinksItem__ttl').on('click', function () {
                $(this).next().stop().slideToggle(300);
                $(this).toggleClass('open');
            })
        }
    }
}

export default FooterAcc;
