/**
 * supportページのアコーディオン
 * @constructor
 */
class SupportAcd {
  constructor() {

  }
  /**
   * supportページのアコーディオン
   */
  init() {
    $(".js-ques").on("click", function () {
      $(this).next(".js-ans").slideToggle("js-show");
      $(this).find(".js-plus").toggleClass("js-none");
    });
  }

}

export default SupportAcd;
