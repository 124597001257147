export default class productSeachAct {
    /**
     *
     * @param {string} searchInputObj 検索ボックスのobj
     * @param {string} tableWrapperObj テーブルを囲んでいるDIV
     *
     */
  constructor(searchInputObj, tableWrapperObj) {
    this.searchInputObj = searchInputObj; // #search-modelNumber
    this.tableWrapper = tableWrapperObj; // .p-tableWrapper
  }
  init() {
    // constructorの変数をsearchWord内で使えるように改めて変数化
    let $searchModelNumber = $(this.searchInputObj),
        pTableWrapper = this.tableWrapper;

    let searchWord = function(){
      let searchText = $searchModelNumber.val(), // 検索ボックスに入力された値
          targetText,
          targetNum;
      
      $(pTableWrapper).each(function () {
        let hiddenNum = 1,
          totalUlNum = $('tr', this).length; // tableのtrの数
        
        $('.modelCell a', this).each(function () {
          targetText = $(this).text();
          targetText = $.trim(targetText);
          
          // toUpperCase()で検索ボックスの値を大文字に変換
          targetNum = targetText.indexOf(searchText.toUpperCase());

          // console.log('検索ボックスに入力された値：' + searchText.toUpperCase() + '\n'
          //   + 'tableのtrの数'+ totalUlNum + '\n'
          //   + 'Model：'+ targetText + '\n'
          //   + 'indexOfの判定：'+ targetNum);
          
          if(targetNum == -1) { // 検索値に当てはまらなかった場合
            $(this).parents('tr').addClass('hidden');
            hiddenNum++;
            if (totalUlNum == hiddenNum) { // コンテンツを消した数がコンテンツ数と一緒になった場合（＝コンテンツが空）
              $(this).parents(pTableWrapper).addClass('hidden');
              $(this).parents(pTableWrapper).prev().addClass('hidden');
            }
          } else { // 検索値に当てはまった場合
            $(this).parents('tr').removeClass('hidden');
            hiddenNum--;
            $(this).parents(pTableWrapper).removeClass('hidden');
            $(this).parents(pTableWrapper).prev().removeClass('hidden');
          }
          // console.log(totalUlNum);
          // console.log('消した数：' + hiddenNum);
        });
      });
    };
  
    // searchWordの実行
    $(this.searchInputObj).on('input', searchWord);
  }
}
