export default class productLinkAct {
    /**
     *
     * @param {string} divObj eachの対象obj
     * @param {string} parentsObj addCalssする対象obj
     * @param {string} addCalssName 付加するクラス名
     *
     */
    constructor (divObj, parentsObj, addCalssName) {
        this.divObj = divObj; // .bdromcol5 a
        this.parentsObj = parentsObj; // .bdromcol5
        this.addCalssName = addCalssName; // p-tmsectionPdTable__productItemLink
    }
  init() {
    $(this.divObj).each((index, element) => {
      const $this = $(element);
      $this.parents(this.parentsObj).addClass(this.addCalssName);
    });
  }
}
