/**
 * formページ用
 */
class Contact {
    constructor() {
    }
    init() {
        this.pageViewSet()
        $('#formType').find('li').on('click',(e)=>{
            this.formType(e.currentTarget);
        })
        $('#inquiryType').find('li').on('click',(e)=>{
            this.inquiryType(e.currentTarget);
        })
        $('#details').find('li').on('click',(e)=>{
            this.details(e.currentTarget);
        })
    }
    pageViewSet(){
        const formType = $('input[name=formType]').val();
        const formTypeSize = $('.p-contactToggle__item').length;
        //最初の二択
        for(let i = 0; i < formTypeSize; i++){
            let target =$('.p-contactToggle__item').eq(i);
            let tmp = target.find('a').data('type');
            if(formType == tmp){
                target.addClass('is-active');
                this.reqChange(formType);
            }
        }
        //radioボタン
        const inquiryType = $('input[name=inquiryType]').val();
        const inquiryTypeSize = $('#inquiryType li').length;
        if(inquiryType){

            for(let i = 0; i < inquiryTypeSize; i++){
                let target =$('#inquiryType li').eq(i);
                let tmp = $('#inquiryType li').eq(i).text();
                if(inquiryType == tmp){
                    target.addClass('is-checked');
                }
            }
        }

    }
    formType(el){
        let text = "";
        if(!$(el).hasClass('is-active')){
            $('.p-contactToggle__item').removeClass('is-active');
            $(el).addClass('is-active');
            text = $(el).find('a').data('type');
            $('input[name=formType]').val(text);
            this.refreshCheckRadio();
            this.reqChange(text);
        }
    }
    reqChange(t){
        if(t=='IR・その他お問い合わせ'){
            $('.js-change').find('dt').removeClass('req');
            $('.is-formTypeA').css('display','none');
            $('.is-formTypeB').css('display','block');
        }else{
            $('.js-change').find('dt').addClass('req');
            $('.is-formTypeA').css('display','block');
            $('.is-formTypeB').css('display','none');
        }
        $('.p-contactTtl').find('span').text(t);
    }
    refreshCheckRadio(){
        $('#inquiryType .p-contactRadio__item').removeClass('is-checked');
        $('#details .p-contactRadio__item').removeClass('is-checked');
        $('#details .p-contactRadio__item').find('input').prop('checked',false);
        $('input[name=inquiryType]').val('');
    }
    inquiryType(el){
        let text = "";
        $('#inquiryType li').removeClass('is-checked');
        $(el).addClass('is-checked');
        text = $(el).text();
        console.log(text)
        $('#inquiryType input').val(text)
    }
    details(el){
        if(!$(el).find('input').prop('checked')){
            $(el).addClass('is-checked');
            $(el).find('input').prop('checked',true);
        }else{
            $(el).removeClass('is-checked');
            $(el).find('input').prop('checked',false);
        }
    }
}
export default Contact;
