/**
 * 追従サイドナビ
 */
class SideNav {
  constructor() {

  }
  init() {
    var SideNav = $('.js-sideNav');
    var footer = $('.l-footer');
    $(window).on('load scroll', function() {
      if ($(this).scrollTop() > 610) {
        SideNav.css('top', 0);
        SideNav.addClass('is-fixed');
      } else {
        SideNav.css('top', 610 - $(this).scrollTop());
        SideNav.removeClass('is-fixed');
      }if ($(this).scrollTop() > footer.offset().top - SideNav.outerHeight()) {
        SideNav.addClass('u-sp');
      } else {
        SideNav.removeClass('u-sp');
      }
    });
  }
}

export default SideNav;