/**
 * サンプルクラス
 * @constructor
 */
class Sample {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

  }
};

export default Sample;
