/**
 * modal
 */
class Modal {
  constructor() {

  }
  init() {
    $(function() {
      $('.m-btnB').on('click', function() {
        //  ボタンをクリックしたら、クリックしたい要素のdata属性を取得
        let target = $(this).data('modal-link');
        //  上記で取得した要素と同じclass名を持つ要素を取得
        let modal = document.querySelector('.' + target);
        //  その要素にclassを付け替える
        $(modal).toggleClass('is-show');
        $('body').css( {overflow: "hidden"})
      });
      
        //  modalを閉じるときの動作
        $('.p-archive__modalBtn,.p-archive__modalBg').on('click', function() {
        $(this).parents('.p-archive__modal').toggleClass('is-show');
        $('body').css( {overflow: ""})
      });
    });
  }
}

export default Modal;