export default class ProductModalCloseAct {
    /**
     *
     * @param {string} modalObj モーダルのクラス名
     * @param {string} modalCloseBtnObj モーダルのクローズボタンのクラス名
     * @param {string} modalCloseBackObj モーダルの背景のクラス名
     *
     */
    constructor (modalObj,modalCloseBtnObj,modalCloseBackObj) {
      this.modalObj = modalObj; // .p-tmsectionModal-open
      this.modalCloseBtnObj = modalCloseBtnObj; // .p-tmsectionModal__close
      this.modalCloseBackObj = modalCloseBackObj; // .p-tmsectionModal__back
    }
  init() {
    let $modalObj = $(this.modalObj),
      $modalCloseBtnObj = $(this.modalCloseBtnObj),
      $modalCloseBackObj = $(this.modalCloseBackObj),
      $modalTtl = $('.p-tmsectionModalCont__ttl'),
      $modalImg = $('.p-tmsectionModalCont__img'),
      $modalText = $('.p-tmsectionModalCont__text');

    $([$modalCloseBtnObj[0],$modalCloseBackObj[0]]).on('click', (e) => {
      modalCloseAct();
    });
    
    const modalCloseAct = () => {
      $modalText.hide();
      $modalTtl.text('');
      $modalImg.attr('src', '');
      $modalObj.hide();
    }
  }
}
