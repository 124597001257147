/**
 * 文字サイズ切り替え
 */
class FsSwich {
  constructor() {

  }
  init() {
    $('.js-fsSwich').on('click', function() {
      $('.js-fsSwich__memu').slideToggle(100);
      return false;
    });
    $('.js-fsSwich__memu li a').on('click', function() {
      $('.js-fsSwich__memu li a').removeClass('is-active');
      $(this).addClass('is-active');
      var swichClass = $(this).attr('data-js');
      $('html').attr('class',swichClass);
      $('.js-fsSwich__memu').hide();
      sessionStorage.fsSwich = swichClass;
      return false;
    });
    
    if(sessionStorage.getItem('fsSwich') != '') {
      console.log(sessionStorage.getItem('fsSwich'));
      $('html').attr('class',sessionStorage.getItem('fsSwich'));
      var dataJs = '[data-js="' + sessionStorage.getItem('fsSwich') + '"]'
      $(dataJs).addClass('is-active');
    } else {
      $('[data-js="l-mainFs--m"]').addClass('is-active');
    }
  }
}

// sessionStorage.key = 'value';
// sessionStorage.getItem('key')

export default FsSwich;